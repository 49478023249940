/* .App {
  font-family: sans-serif;
  text-align: center;
} */
@media (max-width: 948px) {
  .documentation {
    width: 100%;
    margin-left: 2%;

  }
}

@media (min-width: 949px) {
  .documentation {
    width: 70%;
    margin-left: 7%;
  }
}
