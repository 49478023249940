.grid1 > div {
  background: #eee;
  padding: 1em;
}

.grid1 > div:nth-child(odd) {
  background: #ddd;
}
/* removed grid recently-may have to add back */
/* .grid {
  display: grid;
  grid-template-columns: 30% 20% 50%;
  /* grid-template-columns: repeat(1, 1fr); */
/* column-gap: 1em;
  /* row-gap: 1em; */
/* grid-row-gap: 1em; */
/* grid-auto-rows: minmax(10px, 20px); */
/* } */
/* .grid:hover{
  background-color: cornsilk;
} */

.grid1 {
  display: grid;
  /* grid-template-columns: 30% 25% 25%; */
  /* grid-template-columns: ; */
  align-self: flex-end;
  align-items: inherit;
  column-gap: 1em;
  text-align: center;
  /* grid-auto-rows: ; */
}
html {
  font-family: sans-serif;
}

table {
  /* border-collapse: collapse; */
  border: 2px solid rgb(200, 200, 200);
  letter-spacing: 1px;
  font-size: 0.8rem;
  justify-content: right;
  margin-top: 5;
  background-color: rgb(255, 255, 255);
}
.table:hover {
  background-color: rgb(255, 255, 255)
  /* rgb(240, 240, 235); */
}
.tableNew {
  /* border-collapse: collapse; */
  border: 2px solid rgb(200, 200, 200);
  letter-spacing: 1px;
  font-size: 0.8rem;
  justify-content: right;
  margin-top: 5px;
  width: 100%;
  line-height: 27px;

}
.tableNew td{
 text-align: left;

}
.tableNew tr{
  background-color: rgb(232 241 249)

}
.tableNew:hover {
  background-color: rgb(232 241 249)
  
}
td,
th {
  border: 1px solid rgb(190, 190, 190);
  padding: 10px 20px;
}

td {
  text-align: center;

  /* the styles below allow for the user to click once on table data and all the words are selected simultaneously/together */
  -moz-user-select: all;
  -webkit-user-select: all;
  -ms-user-select: all;
  user-select: all;
}

caption {
  padding: 10px;
}
tr:nth-child(even) td {
  background-color: rgb(255, 255, 255);
}

tr:nth-child(odd) td {
  background-color: rgb(255, 255, 255);
}
button,
input,
select,
textarea {
  font-family: inherit;
  font-size: 100%;
}
input,
textarea,
select {
  width: 100%;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  border-right: none;
  border-left: none;
  border-top: none;
}
input,
textarea {
  -moz-user-select: all;
  -webkit-user-select: all;
  -ms-user-select: all;
  user-select: all;
}
/*border style removes input box border left only the bottom border */
.deleteaccounts {
  background-color: rgb(219, 241, 250);
  border-collapse: collapse;
  border-radius: 20px;
  margin-right: 50px;
  box-sizing: border-box;
  width: 150px;
}
.submitfile {
  background-color: rgb(219, 241, 250);
  border-collapse: collapse;
  border-radius: 20px;
  padding-bottom: 1px;
  width: 150px;
  padding-bottom: 2px;
  margin-left: -87%;
  box-sizing: border-box;
}
.submitfile1 {
  margin-right: 55%;
  /* padding-left: -10%; */
}

.space {
  width: 10px;
  height: auto;
  display: inline-block;
}

/*space class above allows for space between upload and delete button*/
/*margin-left: -87%;  aligns button to the left */
.submitfile:hover,
:focus {
  background-color: rgb(161, 217, 219);
}
.choosefile {
  border-radius: 20px;
  padding: 5px 5px 5px 8px;
  text-align: left;
}

.gridStyle {
  margin-top: 80%;
}

.selected:hover {
  background-color: rgb(166, 212, 250);
  cursor: pointer;
}

.selected:active {
  background-color: rgb(128, 185, 231);
}

.RowAdded {
  /* width: 20%; */
  /* opacity: 1; */
  transition: opacity 2.3s ease-out;
}
.deleterow:hover {
  background-color: rgb(209, 198, 198);
}
.input1 {
  width: 100%;
}
.idToStylePageNumbersHorizontallyInsteadOfVertically {
  list-style: none;
  display: flex;
}
.idToStylePageNumbersHorizontallyInsteadOfVertically > li {
  margin-right: 0.3em;
  /* color: blue; */
  user-select: none;
  cursor: pointer;
  background-color: rgb(146, 219, 164);
  color: rgb(7, 0, 0);
  margin: 3px;
  padding: 5px 15px;
  border-radius: 7px;
}
.liStyle {
  margin-right: 0.3em;
  /* color: blue; */
  user-select: none;
  cursor: pointer;
  background-color: rgb(146, 219, 164);
  color: rgb(7, 0, 0);
  margin: 3px;
  padding: 5px 15px;
  border-radius: 7px;
}
.liStyleclick {
  background-color: rgb(68, 175, 93);
  margin-right: 0.3em;
  /* color: blue; */
  user-select: none;
  cursor: pointer;

  color: rgb(7, 0, 0);
  margin: 3px;
  padding: 5px 15px;
  border-radius: 7px;
}
.liStyle:hover {
  background-color: rgb(110, 224, 139);
}

.searchinput {
  width: 15em;
  display: inline-flex;
  margin-top: 10px;
  margin-left: 1.7%;
  margin-right: -155;
}
.yearselect {
  background-color: rgb(251, 255, 252);
  width: 15em;
  display: block;
  margin: 10px;
}
.yearselect1 {
  /* width: 15em; */
  /* display: block; */
  margin-top: 10px;
  margin-bottom: 1px;
}
.disabledState {
  pointer-events: none;
  /* This makes it not clickable */
  opacity: 0.6;
}
.bold {
  font-weight: bold;
  text-align: left;
}
.tdSaveStyle1 {
  background-color: rgb(72, 177, 177);
}
@media (max-width: 948px) {
  .pricingTableCSS{
    width: 100%;
    margin:2%;
  }}
@media (min-width: 949px) {
.pricingTableCSS{
  margin: 60px;
  margin-top: 25px;
  margin-left: 140px;
  width: 80%;
}}