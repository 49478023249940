.App {
  text-align: center;
}

.nav {
  display: flex;
  justify-content: space-around;
  align-items: center;
  min-height: fit-content;
  background: cornflowerblue;
  color: cornsilk;
}

.nav-style {
  width: 50%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  list-style: none;
}
/* .navbar0 {
  /* overflow: hidden; */

/*   position: fixed; Set the navbar to fixed position 
  top: 1; /* Position the navbar at the top of the page 
  width: 100%; 
  display: flex;
  justify-content: space-around;
  align-items: center;
  list-style: none;
} */

.navbar1 {
  position: relative;
  top: 10%;
  left: 30%;
  width: 50%;
  display: flex;
  /* justify-content: space-around; */
  align-items: center;
}
.navbar2 {
  position: absolute;
  top: 50%;
  left: 60%;
  width: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  /* justify-content: space-around; */
  align-items: center;
}
.navbar3 {
  position: absolute;
  top: 30%;
  left: 70%;
  width: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  /* justify-content: space-around; */
  align-items: center;
}

.loginStyle {
  position: relative;
  top: 20%;
  display: flex;
  justify-content: center;
  /* justify-content: space-around; */
  align-items: center;
}

/* link:active {
  color: mediumblue;
} */
