/* For mobile phones: */

@media (max-width: 948px) {
  .mobile {
    margin-left: 30px;
    margin-right: 30px;
    padding: 10;
  }
}

@media (min-width: 949px) {
  .mobile {
    width: 45%;
    margin-left: 30px;
  }
}
@media (max-width: 948px) {
  .mobile1 {
    display: "grid";
    margin-left: 10px;
  }
}
@media (min-width: 949px) {
  .mobile1 {
    display: inline;
  }
}
@media (max-width: 863px) {
  .mobile2 {
    display: grid;
    text-align: left;
  }
}
@media (min-width: 863px) {
  .mobile2 {
    display: inline-flex;
  }
}

@media (max-width: 948px) {
  .mobile3 {
    height: 100%;
  }
}
@media (min-width: 949px) {
  .mobile3 {
    height: 600px;
  }
}
/* signup css */
@media (max-width: 535px) {
  .mobile4 {
    margin-right: 0px;
    margin-top: 10px;
  }
}
@media (min-width: 536px) {
  .mobile4 {
    margin-left: 25px;
  }
}
@media (max-width: 948px) {
  .mobile5 {
    width: 100%;
  }
}

@media (min-width: 949px) {
  .mobile5 {
    width: 45%;
    margin-left: 30px;
  }
}
@media (max-width: 948px) {
  .mobile6 {
    display: inline;
    width: 100%;

  }
}

@media (min-width: 949px) {
  .mobile6 {
    display: flex;
   
  }
}
@media (max-width: 948px) {
  .mobile7 {
    width: 95%;
    margin-left: 11px;
    margin-bottom: 15px;
  }
}

@media (min-width: 949px) {
  .mobile7 {
    width: 50%;
    margin: 10px;
  }
}
@media (max-width: 948px) {
  .mobile8 {
    width: 100%;
  
  }
}
@media (max-width: 948px) {
  .mobile9 {
    width: 95%;
    margin-left: -6px;
    margin-bottom: 15px;
 
  }
}

@media (min-width: 949px) {
  .mobile9 {
    width: 50%;
    margin: 10px;
  }
}
@media (max-width: 948px) {
  .mobile10 {
    padding: 20px;
    padding-top: 50px;
 font-size: 42px;
 text-align: center;
 width: 100%;
  }
}

@media (min-width: 949px) {
  .mobile10 {
    padding: 20px;
   padding-top: 11%;
    text-align: left;
    width: 50%;
    font-size: 350%;
  }
}
@media (max-width: 948px) {
  .mobile11 {
    width:100%
 
  }
}

@media (min-width: 949px) {
  .mobile11 {
    width:70%
  }
}
@media (max-width: 948px) {
  .mobile12 {
   
    margin-bottom: 15px;
 
  }
}

@media (min-width: 949px) {
  .mobile12 {
    width: 50%;
    margin: 10px;
  }
}
@media (max-width: 948px) {
  .mobile13 {
    width: 100%;
    margin-left: 0%;
 
  }
}

@media (min-width: 949px) {
  .mobile13 {
    width: 50%;
    margin-left: 25%;
  }}
  @media (max-width: 948px) {
    .mobile14 {
      display: inline;
      width: 100%;
      margin:4%
    }
  }
  
  @media (min-width: 949px) {
    .mobile14 {
      display: flex;
      margin: 10;
       width: 90%;
        margin-left: 5%
    }
  }
  @media (max-width: 948px) {
    .imageStyle {
   width: 85%;
     margin-left: 1%;
     margin-top: 1%;
    }
  }
  @media (min-width: 949px) {
    .imageStyle { transition: transform 5s;
      width: 50%;
      margin-left: 45%;
     margin-top: -15%;
    }
  }
  .imageStyle:hover {
    transform: scale(1.5); /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
  }
  @media (max-width: 948px) {
    .mobile15 {
   text-align: center;
   margin-top: 10%;
    }
  }
  @media (min-width: 949px) {
    .mobile15 {
     text-align: left;
     margin-top: -10%;
    }
  }
  @media (max-width: 948px) {
    .mobile16 {
      width: 90%; height: 100%
    }
  }
  @media (min-width: 949px) {
    .mobile16 {
      width: 70%; height: 100%
    }
  }
  @media (max-width: 948px) {
    .mobile17 {
    display: none;
    }
  }
  